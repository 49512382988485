.textAlignment{
  text-align: center;
  margin-top: 70px;
  width: 100%;
  
}

.servicesHeadingText{
  font: normal normal bold 40px/1.35em poppins-semibold,poppins,sans-serif;
  vertical-align: baseline;
  margin-bottom: 40px;
  font-weight: 900;
}

.paragraphText{
    line-height: 1.875em;
    font: normal normal normal 15px/1.875em proxima-n-w01-reg,proxima-n-w05-reg,sans-serif;
}

.firstImagePosition{
  background-image: url(../images/computerhardware.jpg);
  height: 460px;
  background-size: cover;
  background-position: center;
  position: relative;
  margin-left: 15%;
  margin-right: 50%;
  margin-bottom: 55px;
  margin-top: 50px;
}

.secondImagePosition{
  background: url(../images/architecture.jpg);
  height: 460px;
  background-size: cover;
  background-position: center;
  position: relative;
  margin-left: 15%;
  margin-right: 50%;
  margin-bottom: 55px;
}

.thirdImagePosition{
  background: url(../images/networking.jpg);
  height: 460px;
  background-size: cover;
  background-position: center;
  position: relative;
  margin-left: 15%;
  margin-right: 50%;
  margin-bottom: 55px;
}


.textBox{
  background-color: black;
  color: white;
  text-align: center;
  position: absolute;
  width: 100%;
  left: 100%;
  padding-right: 20px;
  padding-left: 20px;
  word-wrap: break-word;
  height: 460px;
}

.headingText{
  font: normal normal normal 22px/1.41em poppins-semibold,poppins,sans-serif;
  font-size: 26px;
  font-weight: bold;
  margin-top: 65px;
  text-align: left;
  margin-bottom: 20px;
}

.textAlign{
  text-align: left;
  box-sizing: border-box;
  display: flex;
  line-height: 1.875em;
}


@media (max-width: 340px) {
    .servicesHeadingText {
      font-weight: 400;
    }
  }

  @media (max-width: 946px) {
    .textAlign {
      font-size: 1.5vw;
    }
  }

  @media (max-width: 665px) {
    .headingText {
      font-size: 3vw;
    }
  }

  @media(max-width: 400px){
    .firstImagePosition{
      height: 300px;
    }

    .secondImagePosition{
      height: 300px;
    }

    .thirdImagePosition{
      height: 300px;
    }

    .textBox{
      height: 300px;
    }
  }