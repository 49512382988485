.contactUsForm1 {
    border: none;
    background: #f3f3f3;
    width: 100%;
    padding: 0.375rem 0.75rem;                               
    display: block;
    border-color: #000;
    margin-top: 15px;
    border-radius: 0.25rem;

    /* display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s */
}

.contactusFormContainer {
    max-width: 720px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
    display: block;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
}

.right-side-div-container {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
}


.left-side-div-container {
    flex: 0 0 50%;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
}

.error-message-input-box{
    font-weight: 400;
    text-align: left;
    font-family: "Roboto", sans-serif;
    display: inline-block;
    margin-bottom: auto;                   
    font-size: 12px;
    color: red;

}

.heading-style {
    font-size: 2.5rem;
    font-weight: 900;
    margin-bottom: 1.5rem!important;
}

.paragraph-style {
    margin-top: 0;
    margin-bottom: 1rem;

    font-family: "Roboto", sans-serif;
    background-color: #fff;
    line-height: 1.9;
    color: #8c8c8c;

    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;

    box-sizing: border-box;
}

.send-message-btn {
    margin: 0;
    font-family: inherit;
    overflow: visible;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    font-size: 1rem;
    line-height: 1.5;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    padding-top: 0.5rem!important;
    padding-bottom: 0.5rem!important;
    padding-right: 1.5rem!important;
    padding-left: 1.5rem!important;
    height: 50px;
    border: none;
    border-radius: 4px !important;
    cursor: pointer;
    background: #000;
    color: #fff;
    margin-top: 15px;
}

.form-control-errorBox {

    box-sizing: border-box;
    margin: 0;
    font-family: inherit;
    overflow: auto;
    resize: vertical;
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    border-color: red;
    border-width: 1px;
    background-color: rgba(255, 64, 64, 0.1);
    height: auto;
    margin-top: 15px;


}

.contactUsFormName {
    border: none;
    background: #f3f3f3;
    width: -webkit-fill-available;
    padding: 0.375rem 0.75rem;
    display: table;
    border-color: #000;
    margin-top: 0px;
    border-radius: 0.25rem;
}