.containerForTextAndImage {
  display: flex;
  justify-content: center;
  padding-top: 114px;
  padding-bottom: 15%;
}

.containerOverflow{
  overflow: hidden;
  width: 770px;
}

.imgBgPosition {
  background-image: url(../images/watchAboutPage.jpg);
  width: 100%;
}

.imgPosition {
  height: 770px;
  background-size: cover;
}

@media (min-width: 300px) {
  .containerTextBoxPosition {
    z-index: 1;
    background-color: black;
    color: white;
    max-width: max-content;
    text-align: center;
    margin-top: 65px;
    padding-right: 70px;
    padding-left: 70px;
    position: absolute;
    width: 320px;
  }
}

@media (min-width: 300px) {
  .imgBgPosition {
    position: relative;
  }
}

@media (min-width: 576px) {
  .containerTextBoxPosition {
    z-index: 1;
    background-color: black;
    color: white;
    max-width: max-content;
    text-align: center;
    margin-top: 65px;
    padding-right: 70px;
    padding-left: 70px;
    position: absolute;
  }
}

@media (min-width: 576px) {
  .imgBgPosition {
    position: relative;
  }
}

@media (min-width: 1024px) {
  .containerTextBoxPosition {
    z-index: 1;
    background-color: black;
    color: white;
    max-width: max-content;
    text-align: center;
    margin-top: 65px;
    padding-right: 70px;
    padding-left: 70px;
    position: absolute;
    margin-right: 30%;
  }
}

@media (min-width: 1024px) {
  .imgBgPosition {
    position: relative;
    margin-left: 20%;
  }
}

