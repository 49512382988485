
@keyframes animationEffect {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @-o-keyframes animationEffect {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @-moz-keyframes animationEffect {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @-webkit-keyframes animationEffect {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .imageAnimation {
    -webkit-animation: animationEffect 1s ease-in-out;
    -moz-animation: animationEffect 1s ease-in-out;
    -o-animation: animationEffectt 1s ease-in-out;
    animation: animationEffect 1s ease-in-out;
  }
  
  .textContainerAnimation {
    -webkit-animation: animationEffect 2s ease-in-out;
    -moz-animation: animationEffect 2s ease-in-out;
    -o-animation: animationEffectt 2s ease-in-out;
    animation: animationEffect 2s ease-in-out;
  }
  
  .headingAnimation {
    -webkit-animation: animationEffect 3s ease-in-out;
    -moz-animation: animationEffect 3s ease-in-out;
    -o-animation: animationEffectt 3s ease-in-out;
    animation: animationEffect 3s ease-in-out;
    padding-top: 25px;
    font-weight: 600;
    text-align: left;
  }
  
  .paragraphAnimation {
    -webkit-animation: animationEffect 4s ease-in-out;
    -moz-animation: animationEffect 4s ease-in-out;
    -o-animation: animationEffectt 4s ease-in-out;
    animation: animationEffect 4s ease-in-out;
    padding-top: 25px;
    word-spacing: 1px;
    text-align: left;
    line-height: 1.3em;
    padding-bottom: 40px;
  }
  