.main-content {
    margin-top: 20px;
    padding-left: 2px;
    padding-right: 2px;
}

.container {
    max-width: 1920px;
}

.page-content-col-sm-12
{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

.main-content-container {
    max-width: 2000px;
    margin-top: 20px;
    padding-left: 0px;
    padding-right: 0px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin-right: 0px;
    margin-left: 0px;
}