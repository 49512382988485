.footer{
    width: 100%;
    /* position: relative; */
    text-align: center;
    position: absolute;
}

.footerStay{
    /* position: absolute; */
    bottom: 0px;
    position: relative;
}

.icons{
    color: black;
    font-size: medium;
    margin: 8px;
    padding: 4px;
    margin-left: 1px;
}

#twitter{
    margin: 5px;
}

#facebook{
    margin: 5px;
}

#linkedIn{
    margin: 5px;
}

