.paralsec {
  background-image: url(../images/manworking.jpg);
}

.paralsec1 {
  background-image: url("https://img.webnots.com/2017/05/parallax1.jpg");
}

/* Header Parallax Element Style*/
.paral {
  height: 1200px;
  width: auto;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  margin: 0 auto;
  background-repeat-x: no-repeat;
}

/* Heading for Parallax Section */
.paral h1 {
  font-size: 35px;
  font-weight: bolder;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 20px;
  line-height: 100px;
  margin-top: 92px;
}

#alignit {
  background: rgba(255, 255, 255, 0.2); /* Black background with 0.5 opacity */
  color: black; /* Grey text */
  word-wrap: break-word;
  padding-top: 80px;
  font-weight: bold;
  margin-top: 30px;
  text-align: center;
  /* changes */
  max-width: 650px;
  padding: 20px;
  width: 100%;
}

#companyText {
  font: normal normal normal 20px/1.67em poppins-extralight, poppins, sans-serif;
  color: black;
  font-weight: 500;
  padding: 5px;
  margin: 5px;
}

#companyValueText {
  font: normal normal normal 15px/1.67em poppins-extralight, poppins, sans-serif;
  color: black;
  font-weight: 300;
  padding: 5px;
  margin: 5px;
  word-wrap: break-word;
}

.blockstay {
  position: absolute;
}

#contactUsCompanyInfo{
  text-align: center;
}

#submitBtn{
  width: 50%;
  color: black;
  background-color: white;
  border-color: black;
  margin-top: 18px;
  opacity: 0.7;
  text-decoration: solid;
  font-weight: 800;
  font-size: larger;
}

#submitBtn:hover{
  color: black;
  opacity: 0.3; 
  
}

.errorMessages{
  color: red;
  margin-bottom: 0px;
  
}

.inputBox{
  background-color:  transparent !important;
  border-bottom-color: white;
  border-bottom-width: 2px;
  border-top: transparent;
  border-right: transparent;
  border-left: transparent;

} 

.inputBox:hover{
  border-color: white;
  border-style: solid;
  border-width: 2px;
  border-bottom-color: white;
  border-bottom-width: 2px;
} 

.errorBox{
  border-color: red;
  border-width: 1px;
  background-color:rgba(255, 64, 64, 0.1);
  
}

.col-md-6{
  margin-bottom: 20px;
}

.col-md-12{
  margin-bottom: 6px;
}

.headingText{
  font-size: 55px;
}

.contactFormText{
  color:white;
  font-weight: bolder;
  font-size: large;
}

#contact-form{
  margin-left: 110px;
  margin-right: 110px;
}



#contactFormBackground{
  background-image: url(../images/laptop.jpg);  
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
  margin-top: -24px;
  padding-bottom: 20px;
}

@media (min-width: 650px) {
  .paral h1 {
    font: normal normal normal 72px/1.25em poppins-semibold, poppins, sans-serif;
    font-weight: bold;
  }
}
