.client-video-text-h2 {
    word-wrap: break-word;
    padding: 0;
    border: 0;
    outline: 0;
    vertical-align: baseline;
    background: transparent;
    font: normal normal normal 40px/1.35em poppins-semibold,poppins,sans-serif;
    color: #000000;
    pointer-events: auto;
    margin: 0;
    letter-spacing: normal;
    text-align: center;
    line-height: 1.35em;
  
    
  }

  .client-video-text-styles-one{
    word-wrap: break-word;
    font: normal normal normal 40px/1.35em poppins-semibold,poppins,sans-serif;
    pointer-events: auto;
    letter-spacing: normal;
    text-align: center;
    line-height: 1.35em;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    vertical-align: baseline;
    background: transparent;
    color: #FFFFFF;
  }

  .client-video-text-styles {
    word-wrap: break-word;
    font: normal normal normal 40px/1.35em poppins-semibold,poppins,sans-serif;
    pointer-events: auto;
    letter-spacing: normal;
    text-align: center;
    line-height: 1.35em;
    color: #FFFFFF;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    vertical-align: baseline;
    background: transparent;
    font-weight: bold;
  
    
  }

  .client-list-section {
  position: relative;
  border: 3px solid #fff;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  }


  .client-box-one-container {
    display: flex;
    text-align: center;
    position: relative;
    background-color: rgb(223, 238, 238);
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .client-list-div1 {
    flex: 1;
    margin-left: 32px;
    margin-right: 32px;
    margin-bottom: 32px;
    margin-top: 32px;
    max-width: 100%;

  }

  .client-list-div2 {
    flex: 2;
  }

  
  .client-box-one {
    width: 100%;
    position: absolute;
    top: 0px;
    bottom: 0px;
  }

  .client-box-one-image {
    font-size: 10px;
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    vertical-align: baseline;
    background: transparent;
    width: 220px;
    height: 280px;
    object-fit: contain;
    object-position: center center;
  }



  .client-box-one-h {
    word-wrap: break-word;
    visibility: inherit;
    padding: 0;
    border: 0;
    outline: 0;
    vertical-align: baseline;
    background: transparent;
    font: normal normal normal 22px/1.41em poppins-semibold,poppins,sans-serif;
    color: #000000;
    pointer-events: auto;
    margin: 0;
    letter-spacing: normal;
    text-align: center;
    line-height: 1.41em;
    font-weight: bold;
  }

  .client-box-one-p {
    word-wrap: break-word;
    width: 200px;
    visibility: inherit;
    padding: 0;
    border: 0;
    outline: 0;
    vertical-align: baseline;
    background: transparent;
    font: normal normal normal 15px/1.875em proxima-n-w01-reg,proxima-n-w05-reg,sans-serif;
    color: #000000;
    pointer-events: auto;
    letter-spacing: normal;
    text-align: center;
    line-height: 1.875em;
  }