.plain-card-3 {
  position: relative;
  border-radius: 10px;
  background: #000000c4;
  width: 310px;
  max-width: 310px;
  margin-bottom: 20px;
}

.outer-clip-details {
  font-weight: bold;
  font-size: 35px;
  padding: 10px;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  max-width: 300px;
  padding: 15px 25px;
  color: #353535;
  background: url("../images/cybersecurity.jpg") no-repeat;
  background-size: cover;
  -webkit-text-fill-color: rgba(255,255,255,0.7);
  -webkit-background-clip: text;
  -moz-webkit-text-fill-color: transparent;
  -moz-webkit-background-clip: text;
}

.clip-card-container-3 {
  position: relative;
  clip-path: circle(0px at 0px 0px);
  transition: all 1s ease;
  width: 310px;
  max-width: 310px;
  border-radius: 5px;
  background: url("../images/cybersecurity.jpg") no-repeat;
  background-size: cover;
}

.card-details-3 {
  padding: 25px 0;
  font-weight: bold;
  font-size: 22px;
  color: #fff;
}

.plain-card-3:hover .clip-card-container-3 {
  clip-path: circle(100%);
}

.centerCards{
    display: flex;
    justify-content: space-around;
    max-width: 1000px;   
    margin: 100px auto;
    flex-wrap: wrap;
}