.section-video {
    position: relative;
}

.div-client-video {
    max-height: 450px;
    overflow: hidden;
    position: relative;
}

.video-style {
    width: 100%;
    height: auto;
    position: relative;
}

.video-text {
    position: absolute;
    z-index:90;
    top: 0;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    align-items: center;
}

.video-text h2 {
    color: #fff;
    font-weight: bold;
}

